exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-bungee-jumping-js": () => import("./../../../src/pages/activities/bungee-jumping.js" /* webpackChunkName: "component---src-pages-activities-bungee-jumping-js" */),
  "component---src-pages-activities-paragliding-js": () => import("./../../../src/pages/activities/paragliding.js" /* webpackChunkName: "component---src-pages-activities-paragliding-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-bus-remorino-js": () => import("./../../../src/pages/bus/remorino.js" /* webpackChunkName: "component---src-pages-bus-remorino-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eco-friendly-js": () => import("./../../../src/pages/eco-friendly.js" /* webpackChunkName: "component---src-pages-eco-friendly-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-house-rules-js": () => import("./../../../src/pages/house-rules.js" /* webpackChunkName: "component---src-pages-house-rules-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-istruzioni-noleggio-js": () => import("./../../../src/pages/istruzioni-noleggio.js" /* webpackChunkName: "component---src-pages-istruzioni-noleggio-js" */),
  "component---src-pages-nature-js": () => import("./../../../src/pages/nature.js" /* webpackChunkName: "component---src-pages-nature-js" */),
  "component---src-pages-parking-js": () => import("./../../../src/pages/parking.js" /* webpackChunkName: "component---src-pages-parking-js" */),
  "component---src-pages-philosophy-js": () => import("./../../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rooms-connecting-lake-js": () => import("./../../../src/pages/_rooms/connecting-lake.js" /* webpackChunkName: "component---src-pages-rooms-connecting-lake-js" */),
  "component---src-pages-rooms-connecting-room-js": () => import("./../../../src/pages/_rooms/connecting-room.js" /* webpackChunkName: "component---src-pages-rooms-connecting-room-js" */),
  "component---src-pages-rooms-family-room-js": () => import("./../../../src/pages/_rooms/family-room.js" /* webpackChunkName: "component---src-pages-rooms-family-room-js" */),
  "component---src-pages-rooms-js": () => import("./../../../src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-rooms-lake-room-js": () => import("./../../../src/pages/_rooms/lake-room.js" /* webpackChunkName: "component---src-pages-rooms-lake-room-js" */),
  "component---src-pages-rooms-mountain-room-js": () => import("./../../../src/pages/_rooms/mountain-room.js" /* webpackChunkName: "component---src-pages-rooms-mountain-room-js" */),
  "component---src-pages-rooms-studio-js": () => import("./../../../src/pages/_rooms/studio.js" /* webpackChunkName: "component---src-pages-rooms-studio-js" */),
  "component---src-pages-rooms-triple-mountain-room-js": () => import("./../../../src/pages/_rooms/triple-mountain-room.js" /* webpackChunkName: "component---src-pages-rooms-triple-mountain-room-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-view-360-js": () => import("./../../../src/pages/view-360.js" /* webpackChunkName: "component---src-pages-view-360-js" */)
}

